require('./index.css');
require('Common/css/global.css');
require('bootstrap/dist/css/bootstrap.min.css')
import 'jquery';
require('Common/js/common.js');
require('bootstrap');

var beianCodes = {
    'popstarmax.com': '皖ICP备18025386号',
    'popstarmax.cn': '皖ICP备18025386号',
    'kingofclear.com': '皖ICP备18025386号',
    'kingofclear.cn': '皖ICP备18025386号',
    'glaidian.com': '皖ICP备18025386号',
    'glaidian.cn': '皖ICP备18025386号',
    'zuitanqiu.cn': '皖ICP备18025386号',
    'zuitanqiu.com': '皖ICP备18025386号',
    'duoduoplay.cn': '皖ICP备18025386号',
    'duoduoplay.com': '皖ICP备18025386号',
    'juyouliao.cn': '皖ICP备18025386号',
    'gyouliao.com': '皖ICP备18025386号',
    'gyouliao.cn': '皖ICP备18025386号',
    'cwvvv.com': '皖ICP备18025386号',
    'ualll.com': '皖ICP备18025386号',
    'oaggg.com': '皖ICP备18025386号',
    'cahhh.com': '皖ICP备18025386号',
    'banpk.cn': '皖ICP备18025386号',
    '12hi89.cn': '皖ICP备18025386号',
    'wad91.cn': '皖ICP备18025386号',
    'ijngr2.cn': '皖ICP备18025386号',
    'wxz03.cn': '皖ICP备18025386号',
    'tzredian.com': '皖ICP备18025386号',
    'taozuiredian.cn': '皖ICP备18025386号',
};

var offices = {
    'popstarmax.com': '安徽麦游',
    'popstarmax.cn': '安徽麦游',
    'kingofclear.com': '安徽麦游',
    'kingofclear.cn': '安徽麦游',
    'glaidian.com': '安徽麦游',
    'glaidian.cn': '安徽麦游',
    'zuitanqiu.cn': '安徽麦游',
    'zuitanqiu.com': '安徽麦游',
    'duoduoplay.cn': '安徽麦游',
    'duoduoplay.com': '安徽麦游',
    'juyouliao.cn': '安徽麦游',
    'gyouliao.com': '安徽麦游',
    'gyouliao.cn': '安徽麦游',
    'cwvvv.com': '安徽麦游',
    'ualll.com': '安徽麦游',
    'oaggg.com': '安徽麦游',
    'cahhh.com': '安徽麦游',
    'banpk.cn': '安徽麦游',
    '12hi89.cn': '安徽麦游',
    'wad91.cn': '安徽麦游',
    'ijngr2.cn': '安徽麦游',
    'wxz03.cn': '安徽麦游',
    'tzredian.com': '安徽麦游',
    'taozuiredian.cn': '安徽麦游',
};

var myhyoOfficeDesc = '安徽麦游网络科技成立于2 0 1 8 年 9月，是一家集产品、流量及数据挖掘于一身的技术企业，公司致力于打造多产品矩阵，满足用户不同场景的需求。此外，还搭建了流量服务系统，为移动广告主提供优质的流量变现及用户增长等综合服务。';
var officeDesc = {
    'popstarmax.com': myhyoOfficeDesc,
    'popstarmax.cn': myhyoOfficeDesc,
    'kingofclear.com': myhyoOfficeDesc,
    'kingofclear.cn': myhyoOfficeDesc,
    'glaidian.com': myhyoOfficeDesc,
    'glaidian.cn': myhyoOfficeDesc,
    'zuitanqiu.cn': myhyoOfficeDesc,
    'zuitanqiu.com': myhyoOfficeDesc,
    'duoduoplay.cn': myhyoOfficeDesc,
    'duoduoplay.com': myhyoOfficeDesc,
    'juyouliao.cn': myhyoOfficeDesc,
    'gyouliao.com': myhyoOfficeDesc,
    'gyouliao.cn': myhyoOfficeDesc,
    'cwvvv.com': myhyoOfficeDesc,
    'ualll.com': myhyoOfficeDesc,
    'oaggg.com': myhyoOfficeDesc,
    'cahhh.com': myhyoOfficeDesc,
    'banpk.cn': myhyoOfficeDesc,
    '12hi89.cn': myhyoOfficeDesc,
    'wad91.cn': myhyoOfficeDesc,
    'ijngr2.cn': myhyoOfficeDesc,
    'wxz03.cn': myhyoOfficeDesc,
    'tzredian.com': myhyoOfficeDesc,
    'taozuiredian.cn': myhyoOfficeDesc,
};

var defaultAppDesc = ''
var myhyoPopStarAppDesc = '<b>大麦消星星</b><p>大麦消星星是一款画面精美的消除星星游戏。玩家的任务是将同颜色连接在一起的星星消除，只需点击两个或两个以上颜色相同的方块即可成功消除。<br>游戏整体没有时间限制，每个阶段都有一个分数目标，达到指定分数即可进入下一关。<br>游戏虽然简单，但想要玩出很高的分数还是要耗费不少脑细胞的，拿大麦消星星开动脑筋打发时间也是不错的选择。</p>';
var myhyoPopStarApp2Desc = '<b>大麦钻石消消</b><p>大麦钻石消消是一款画面精美的益智类方块消除游戏。玩家的任务是将预选区的图形拖拽填充到游戏棋盘区域，只需要方块整排或者整列连通，就可以将其消除。<br>游戏整体没有时间限制，每个阶段都有一个分数目标，达到指定分数即可进入下一关。<br>游戏内容轻松简单，但同样适合喜欢消除游戏的游戏玩家，而且想要玩出很高的分数还是要耗费不少脑细胞的，偶尔拿大麦钻石消消开动脑筋打发时间也是不错的选择。</p>';
var myhyoPopStarApp3Desc = '<b>萌星消消</b><p>萌星消消是一款画面精美的益智类方块消除游戏。玩家的任务是将预选区的图形拖拽填充到游戏棋盘区域，只需要方块整排或者整列连通，就可以将其消除。<br>游戏整体没有时间限制，每个阶段都有一个分数目标，达到指定分数即可进入下一关。<br>游戏内容轻松简单，但同样适合喜欢消除游戏的游戏玩家，而且想要玩出很高的分数还是要耗费不少脑细胞的，偶尔拿大麦钻石消消开动脑筋打发时间也是不错的选择。</p>';
var myhyoKingOfClearAppDesc = '<b>清理王</b><p>清理王是一款优秀的手机加速与空间清理的软件，深度清理微信垃圾，游戏聊天更流畅，一键清理快速解决空间不足问题，开机加速、聊天加速、为您提供清理垃圾，手机加速，热门软件专清，手机降温，通知栏清理等手机管理服务。<br>垃圾清理：全面智能扫描，一键清理垃圾，彻底释放内存，使用更畅快！<br>短视频清理：智能清理缓存的小视频、让手机告别空间不足、放心刷视频！<br>通知栏清理：智能拦截垃圾信息、一键开启防打扰，跟无用的推送通知说再见！<br>聊天缓存专清：有效清理常用的聊天软件无用垃圾缓存文件、让聊天更快更安全！</p><b>APP预览</b><p><img style="width: 100px;" src="' + require('./images/king-prev1.png') + '"><img style="width: 100px;" src="' + require('./images/king-prev2.png') + '"><img style="width: 100px;" src="' + require('./images/king-prev3.png') + '"><img style="width: 100px;" src="' + require('./images/king-prev4.png') + '"><img style="width: 100px;" src="' + require('./images/king-prev5.png') + '"></p><a href="http://api.walle.banpk.cn/api/v1/redirect/kingofclear/e/newest/official/app.apk">立即下载</a>'
var catSanguoAppDesc = '<b>猫战三国</b><p>《猫战三国》是一款三国题材，合成策略类的团战游戏<br>游戏用拟人化猫咪的表现方式塑造了一个个栩栩如生的三国小兵形象<br>在游戏中，玩家可以成为三国乱世中的一代军神，招募三国名将<br>提升小兵等级，升级装备，解锁兵种，召集军队，出谋划策，不断的抵御入侵者的攻击，闯关升级，最终击败所有敌人，完成一统天下的霸业。</p>'
var glaidianAppDesc = '<b>来电宝</b><p>个性潮流来电视频显示应有尽有，给你全新的炫酷来电体验。 全网火热来电视频主题动画显示，爱上来电的感觉，个性、唯美、酷炫的来电秀视频主题动画显示</p>'
var zuitanqiuAppDesc = '<b>疯狂弹一弹</b><p>上班通勤无聊? 课间休息无趣? 压力大无处发泄?<br><br>轻松解压的疯狂弹一弹来了!<br>无限小球与弹珠等你来消灭</p>'
var appDesc = {
    'popstarmax.com': myhyoPopStarAppDesc + myhyoPopStarApp2Desc + myhyoPopStarApp3Desc + catSanguoAppDesc,
    'popstarmax.cn': myhyoPopStarAppDesc + myhyoPopStarApp2Desc + myhyoPopStarApp3Desc + catSanguoAppDesc,
    'kingofclear.com': myhyoKingOfClearAppDesc,
    'kingofclear.cn': myhyoKingOfClearAppDesc,
    'glaidian.com': glaidianAppDesc,
    'glaidian.cn': glaidianAppDesc,
    'zuitanqiu.cn': defaultAppDesc,
    'zuitanqiu.com': defaultAppDesc,
    'duoduoplay.cn': defaultAppDesc,
    'duoduoplay.com': defaultAppDesc,
    'juyouliao.cn': defaultAppDesc,
    'gyouliao.com': defaultAppDesc,
    'gyouliao.cn': defaultAppDesc,
    'cwvvv.com': defaultAppDesc,
    'ualll.com': defaultAppDesc,
    'oaggg.com': defaultAppDesc,
    'cahhh.com': defaultAppDesc,
    'banpk.cn': defaultAppDesc,
    '12hi89.cn': defaultAppDesc,
    'wad91.cn': defaultAppDesc,
    'ijngr2.cn': defaultAppDesc,
    'wxz03.cn': defaultAppDesc,
    'tzredian.com': defaultAppDesc,
    'taozuiredian.cn': defaultAppDesc,
};

var contact = {
    'popstarmax.com': 'feedback@myhayo.com',
    'popstarmax.cn': 'feedback@myhayo.com',
    'kingofclear.com': 'feedback@myhayo.com',
    'kingofclear.cn': 'feedback@myhayo.com',
    'glaidian.com': 'feedback@myhayo.com',
    'glaidian.cn': 'feedback@myhayo.com',
    'zuitanqiu.cn': 'feedback@myhayo.com',
    'zuitanqiu.com': 'feedback@myhayo.com',
    'duoduoplay.cn': 'feedback@myhayo.com',
    'duoduoplay.com': 'feedback@myhayo.com',
    'juyouliao.cn': 'feedback@myhayo.com',
    'gyouliao.com': 'feedback@myhayo.com',
    'gyouliao.cn': 'feedback@myhayo.com',
    'cwvvv.com': 'feedback@myhayo.com',
    'ualll.com': 'feedback@myhayo.com',
    'oaggg.com': 'feedback@myhayo.com',
    'cahhh.com': 'feedback@myhayo.com',
    'banpk.cn': 'feedback@myhayo.com',
    '12hi89.cn': 'feedback@myhayo.com',
    'wad91.cn': 'feedback@myhayo.com',
    'ijngr2.cn': 'feedback@myhayo.com',
    'wxz03.cn': 'feedback@myhayo.com',
    'tzredian.com': 'feedback@myhayo.com',
    'taozuiredian.cn': 'feedback@myhayo.com',
};

var myhayoAddress = '中国（上海）自由贸易试验区博霞路22号202室 电话：021-20958871';
var myhyoAddress = '合肥蜀山区合肥创新产业园A1楼405室';
var address = {
    'popstarmax.com': myhyoAddress,
    'popstarmax.cn': myhyoAddress,
    'kingofclear.com': myhyoAddress,
    'kingofclear.cn': myhyoAddress,
    'glaidian.com': myhyoAddress,
    'glaidian.cn': myhyoAddress,
    'zuitanqiu.cn': myhyoAddress,
    'zuitanqiu.com': myhyoAddress,
    'duoduoplay.cn': myhyoAddress,
    'duoduoplay.com': myhyoAddress,
    'juyouliao.cn': myhyoAddress,
    'gyouliao.com': myhyoAddress,
    'gyouliao.cn': myhyoAddress,
    'cwvvv.com': myhyoAddress,
    'ualll.com': myhyoAddress,
    'oaggg.com': myhyoAddress,
    'cahhh.com': myhyoAddress,
    'banpk.cn': myhyoAddress,
    '12hi89.cn': myhyoAddress,
    'wad91.cn': myhyoAddress,
    'ijngr2.cn': myhyoAddress,
    'wxz03.cn': myhyoAddress,
    'tzredian.com': myhyoAddress,
    'taozuiredian.cn': myhyoAddress,
}

$(function () {
    var matchs = location.host.match(/^(.+\.+)?(.+\..+)$/);
    var domain = matchs && matchs[2] || 'popstarmax.com';

    if (beianCodes[domain]) {
        $('#link-beian-code').text(beianCodes[domain]);
    } else {
        $('#link-beian-code').remove();
    }

    if (offices[domain]) {
        window.document.title = offices[domain];
        $('[name=office-name]').html(offices[domain]);
    }

    if (officeDesc[domain]) {
        $('[name=office-desc]').html(officeDesc[domain]);
    }

    if (appDesc[domain]) {
        // 'app-desc'
        $('[name=app-desc]').html(appDesc[domain]);
    }

    if (contact[domain]) {
        $('[name=office-contact]').attr('href', 'mailto:' + contact[domain]);
        $('[name=office-contact]').html(contact[domain]);
    }

    if (address[domain]) {
        $('[name=office-address]').html(address[domain]);
    }
});
